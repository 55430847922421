import EventBus from '@/services/eventBus/eventBus';

const NotificationService = {
  displayErrorNotification(message: string): void {
    EventBus.emit('notification', {
      type: 'error',
      message,
    });
  },
  displaySuccessNotification(message: string): void {
    EventBus.emit('notification', {
      type: 'success',
      message,
    });
  },
  displayInfoNotification(message: string): void {
    EventBus.emit('notification', {
      type: 'info',
      message,
    });
  },
  displayWarningNotification(message: string): void {
    EventBus.emit('notification', {
      type: 'warning',
      message,
    });
  },
};

export default NotificationService;
