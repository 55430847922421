<template>
  <nav :aria-label="label" role="navigation">
    <ul>
      <li v-for="(link, index) of links" :key="index">
        <a class="link" :href="link.href" :target="link.target">
          {{ link.linkText }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

interface LinkListItem {
  href: string;
  target: string;
  linkText: string;
}

export default defineComponent({
  name: 'LinkList',
  props: {
    links: {
      type: Array as PropType<LinkListItem[]>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
}

li {
  padding-bottom: 10px;

  &:last-child {
    padding: 0;
  }
}

.link:link,
.link:visited {
  text-decoration: none;
  border-bottom: 1px solid currentColor;
  font-weight: 700;
  color: $aux-white;
}
</style>
