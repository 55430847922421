<template>
  <footer class="footer-content">
    <div class="footer-main-content">
      <div class="footer-contact">
        <h2 class="footer-header">Kontakt</h2>

        <div class="company-contact">
          <div class="company-contact-data">
            <div>{{ companyContactInfo.companyName }}</div>
            <div>{{ companyContactInfo.companyStreetAddress }}</div>
            <div>{{ companyContactInfo.companyPostBox }}</div>
            <div>
              {{ companyContactInfo.companyPostalNumber }}
              {{ companyContactInfo.companyPostalPlace }}
            </div>
            <div>Norge</div>
          </div>

          <LinkList
            class="company-contact-data"
            :links="companyLinks"
            label="Ambita kontaktmuligheter"
          />
        </div>
      </div>

      <div class="footer-customer-service">
        <h2 class="footer-header">Kundeservice</h2>

        <LinkList :links="customerServiceLinks" label="Ambita Kundeservice kontaktmuligheter" />
      </div>

      <div class="footer-info-capsules">
        <h2 class="footer-header">Informasjonskapsler</h2>

        <p>
          Ved å bruke våre nettsider samtykker du i at vi kan sette informasjonskapsler (cookies) i
          din nettleser. Informasjonen vi samler inn bruker vi til å analysere trafikken på
          nettstedet, slik at vi kan gjøre nettsidene våre bedre for våre brukere.
        </p>
      </div>
    </div>

    <div class="footer-bottom-content">
      Tjenesten leveres av <a class="link" href="https://www.ambita.com/">Ambita AS</a>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LinkList from '@/components/LinkList/LinkList.vue';

export default defineComponent({
  components: {
    LinkList,
  },
  name: 'KodeFooter',
  setup() {
    const customerServiceLinks = [
      {
        href: `tel:+47${import.meta.env.VITE_COMPANY_SUPPORT_PHONE.replaceAll(' ', '')}`,
        target: '_blank',
        linkText: `(+47) ${import.meta.env.VITE_COMPANY_SUPPORT_PHONE}`,
      },
      {
        href: `mailto:${import.meta.env.VITE_COMPANY_SUPPORT_MAIL}`,
        target: '_blank',
        linkText: import.meta.env.VITE_COMPANY_SUPPORT_MAIL,
      },
      {
        href: import.meta.env.VITE_COMPANY_REMOTE_ASSISTANCE,
        target: '_blank',
        linkText: 'Fjernhjelp',
      },
    ];

    const companyContactInfo = {
      companyName: import.meta.env.VITE_COMPANY_NAME,
      companyStreetAddress: import.meta.env.VITE_COMPANY_STREET,
      companyPostBox: import.meta.env.VITE_COMPANY_POSTAL_BOX,
      companyPostalNumber: import.meta.env.VITE_COMPANY_POSTAL_NUMBER,
      companyPostalPlace: import.meta.env.VITE_COMPANY_POSTAL_PLACE,
    };

    const companyLinks = [
      {
        href: `tel:+47${import.meta.env.VITE_COMPANY_PHONE.replaceAll(' ', '')}`,
        target: '_blank',
        linkText: `(+47) ${import.meta.env.VITE_COMPANY_PHONE}`,
      },
      {
        href: `mailto:${import.meta.env.VITE_COMPANY_MAIL}`,
        target: '_blank',
        linkText: import.meta.env.VITE_COMPANY_MAIL,
      },
      {
        href: import.meta.env.VITE_COMPANY_WEBPAGE,
        target: '_blank',
        linkText: 'ambita.com',
      },
      {
        href: import.meta.env.VITE_INFOLAND_WEBPAGE,
        target: '_blank',
        linkText: 'infoland.no',
      },
    ];

    return { customerServiceLinks, companyContactInfo, companyLinks };
  },
});
</script>

<style lang="scss" scoped>
$width-left-right-items: 370px;
$border-style: 1px solid $aux-purple;

.footer-header {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  border-bottom: $border-style;
}

.link:link,
.link:visited {
  text-decoration: none;
  border-bottom: 1px solid currentColor;
  font-weight: 700;
  color: $aux-white;
}

.footer-content {
  display: flex;
  flex-direction: column;
  background: $aux-dark-purple;
  color: $aux-white;
  text-align: center;
  padding: 0 25px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  z-index: $menu-position;
}

.footer-main-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px auto;
  width: 100%;
  flex: 1;
  text-align: left;
}

.footer-bottom-content {
  padding: 10px;
  border-top: $border-style;
  width: 100%;
  margin: 0 auto;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  width: 450px;
}

.company-contact {
  display: flex;
  flex-direction: row;
}

.company-contact-data {
  width: 49%;

  &:last-child {
    padding-left: 15px;
  }
}

.footer-customer-service {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 5px;
}

.footer-info-capsules {
  display: flex;
  flex-direction: column;
  width: 450px;

  p {
    margin-top: 0;
  }
}

@media (max-width: 1024px) {
  .footer-customer-service {
    width: $width-left-right-items;
    margin: 0;
  }

  .footer-info-capsules {
    width: 100%;
  }

  .footer-info-capsules {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .footer-customer-service {
    width: $width-left-right-items;
    margin: 0;
  }

  .footer-info-capsules {
    width: 100%;
  }
}

@media (max-width: 805px) {
  .footer-contact {
    width: 100%;
  }

  .footer-customer-service {
    margin: 0;
    width: 100%;
  }

  .footer-info-capsules {
    width: 100%;
  }
}

@media (max-width: 372px) {
  .footer-customer-service {
    margin-left: 0;
  }

  .footer-content {
    font-size: 10px;
  }
}
</style>
