<template>
  <header :class="classes">
    <router-link class="company-logo" to="/ordre" aria-label="Gå til ordrelisten">
      <aux-logo />
    </router-link>

    <button class="menu-toggler" aria-label="vis/skjul hovedmeny" @click="toggleMenu">
      <close-icon v-if="state.showMainMenu" />
      <menu-icon v-if="!state.showMainMenu" />
    </button>

    <kode-menu
      class="product-menu"
      :activeMenu="state.activeMenu"
      @change-active-menu="changeActiveMenu"
      @close-main-menu="closeMainMenu"
    />

    <user-menu
      class="user-menu"
      :activeMenu="state.activeMenu"
      @change-active-menu="changeActiveMenu"
      @close-main-menu="closeMainMenu"
    />
  </header>
</template>

<script lang="ts">
import { reactive, defineComponent, computed } from 'vue';
import { AuxLogo } from '@ambita/design-system';
import KodeMenu from '@/components/Menu/Menu.vue';
import UserMenu from '@/components/UserMenu/UserMenu.vue';
import CloseIcon from '@icons/regular/x.svg';
import MenuIcon from '@icons/regular/list.svg';

export default defineComponent({
  name: 'KodeHeader',
  setup() {
    const state = reactive({
      showMainMenu: false,
      activeMenu: '',
    });

    const closeMainMenu = () => {
      state.showMainMenu = false;
      state.activeMenu = '';
    };

    const changeActiveMenu = (selectedActiveMenu: string) => {
      state.activeMenu = selectedActiveMenu;
    };

    const toggleMenu = () => {
      state.activeMenu = '';
      state.showMainMenu = !state.showMainMenu;
    };

    const classes = computed(() => {
      return { 'menu-open': state.showMainMenu };
    });

    return {
      toggleMenu,
      state,
      classes,
      changeActiveMenu,
      closeMainMenu,
    };
  },
  components: {
    KodeMenu,
    AuxLogo,
    UserMenu,
    CloseIcon,
    MenuIcon,
  },
});
</script>

<style lang="scss" scoped>
header {
  display: flex;
  flex-direction: column;
  background-color: $aux-dark-purple;
  width: 100%;
  z-index: $menu-position;

  @media screen and (min-width: $display-hamburger-menu-breakpoint) {
    flex-direction: row;
  }

  &.menu-open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @media screen and (min-width: $display-hamburger-menu-breakpoint) {
      position: static;
    }
  }
}

.company-logo {
  background-color: $aux-white;
  min-width: 140px;
  max-width: 140px;
  padding: 10px 20px;

  .menu-open & {
    display: none;

    @media screen and (min-width: $display-hamburger-menu-breakpoint) {
      display: flex;
    }
  }
}

.menu-toggler {
  width: 50px;
  border: none;
  color: $aux-white;
  position: absolute;
  right: 0;
  @include aux-focus-indicator;

  &:hover,
  &:active {
    border: none;
  }

  @media screen and (min-width: $display-hamburger-menu-breakpoint) {
    display: none;
  }
}

.product-menu {
  display: none;
  margin-top: 50px;
  margin-right: auto;

  @media screen and (min-width: $display-hamburger-menu-breakpoint) {
    display: inline;
    margin-top: 0;
  }

  .menu-open & {
    display: block;
  }
}

.user-menu {
  display: none;

  @media screen and (min-width: $display-hamburger-menu-breakpoint) {
    display: flex;
  }

  .menu-open & {
    display: flex;
  }
}
</style>
