type MenuItem = {
  label: string;
  path: string;
  children?: SubMenuItem[];
};

type SubMenuItem = {
  label: string;
  path: string;
};

const UserMenuItems: SubMenuItem[] = [
  { label: 'Brukere', path: '/brukere' },
  { label: 'Avdelinger', path: '/avdelinger' },
];

const MessageMenuItems: SubMenuItem[] = [
  { label: 'Melding i Infoland', path: '/meldinger/infoland' },
  { label: 'Melding til formidlere', path: '/meldinger' },
];
const SettingsMenuItems: SubMenuItem[] = [
  { label: 'Mottaksadresse for ordremelding', path: '/innstillinger/mottaksadresse-ordremelding' },
];

const Menu: MenuItem[] = [
  { label: 'Ordre', path: '/ordre' },
  { label: 'Produkter', path: '/produkter' },
  { label: 'Meldinger', path: '/meldinger', children: MessageMenuItems },
  { label: 'Rapporter', path: '/rapporter' },
  { label: 'Brukere', path: '/brukere', children: UserMenuItems },
  { label: 'Innstillinger', path: '/innstillinger', children: SettingsMenuItems },
];

export default Menu;
