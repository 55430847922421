const preventFileUploadRedirects = (event: DragEvent) => {
  // Prevent browser from navigating if the user misses the dropzones.
  const target = event.target as HTMLElement;

  if (target.getAttribute('type') !== 'file') {
    event.preventDefault();
  }
};

export default {
  mount: (): void => {
    window.addEventListener('dragover', preventFileUploadRedirects, false);
    window.addEventListener('drop', preventFileUploadRedirects, false);
  },
  unmount: (): void => {
    window.removeEventListener('dragover', preventFileUploadRedirects, false);
    window.removeEventListener('drop', preventFileUploadRedirects, false);
  },
};
