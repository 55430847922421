import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import AuthenticationService from '@/services/authentication/authenticationService';
import NotificationService from '@/services/notifications/notificationService';
import { SUPPLIERS_BASE_URL } from '@/constants/urls';

const errorHandler = (error: AxiosError) => {
  throw error;
};

const successHandler = (response: AxiosResponse) => {
  return response;
};

const requestHandler = async (request: AxiosRequestConfig) => {
  const isAuthentication = request.baseURL === `${SUPPLIERS_BASE_URL}/authentication`;
  const isTokenRefresh = request.url === '/refresh';
  const isLoginAttempt = request.url === '/login';
  const now = new Date().getTime();
  const cookieExpiry = parseInt(localStorage.getItem('kode-token-expiry') || '0', 10);
  const tokenHasExpired = cookieExpiry < now;

  if (tokenHasExpired && !isAuthentication && !isTokenRefresh && !isLoginAttempt) {
    await AuthenticationService.refreshAuthentication().catch((error: AxiosError) => {
      switch (error.response?.status) {
        case 400:
        case 401:
        case 403:
          console.warn(
            `Refresh of token failed, server responded with ${error.response?.status}. Running logout routine`
          );
          AuthenticationService.logoutUser();
          break;
        default:
          console.error(
            `Refresh of token failed, server responded with ${error.response?.status}. Leaving login state alone`
          );
          NotificationService.displayErrorNotification(
            'Noe gikk galt, forsøk å laste siden på nytt.'
          );
          break;
      }
    });
  }

  return request;
};

export default (baseURL: string): AxiosInstance => {
  const http = axios.create({
    baseURL,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });

  http.interceptors.request.use(requestHandler);
  http.interceptors.response.use(successHandler, errorHandler);

  return http;
};
