<template>
  <li :class="classes">
    <component :is="icon" class="icon" aria-hidden="true" />
    <slot />
  </li>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, ref } from 'vue';
import SuccessBannerIcon from '@icons/regular/check-circle.svg';
import InfoBannerIcon from '@icons/regular/info.svg';
import WarningBannerIcon from '@icons/regular/warning-circle.svg';
import ErrorBannerIcon from '@icons/regular/warning.svg';
export default defineComponent({
  props: {
    type: {
      type: String as PropType<'success' | 'error' | 'warning' | 'info'>,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  name: 'KodeNotification',
  setup(props, { emit }) {
    const display = ref(false);

    const classes = computed(() => ({
      message: true,
      [`message--${props.type}`]: props.type,
      'message-show': display.value,
    }));

    setTimeout(() => {
      display.value = true;
    }, 100);

    setTimeout(() => {
      emit('remove-notification', props.id);
    }, 3000);

    const icon = computed(() => {
      switch (props.type) {
        case 'error':
          return ErrorBannerIcon;
        case 'warning':
          return WarningBannerIcon;
        case 'success':
          return SuccessBannerIcon;
        default:
          return InfoBannerIcon;
      }
    });

    return { classes, icon };
  },
});
</script>

<style lang="scss" scoped>
.message {
  display: flex;
  padding: 10px 20px;
  background: #fff;
  border: 1px solid;
  border-left: 80px solid;
  border-radius: 3px;
  box-shadow: 0 0 0 3px rgba($aux-black, 0.1);
  list-style: none;
  transform: translateX(300px);
  transition: transform 0.2s ease-in-out;
  position: relative;

  &--error {
    border-color: $aux-red;
  }
  &--warning {
    border-color: $aux-yellow;
  }
  &--info {
    border-color: $aux-blue;
  }
  &--success {
    border-color: $aux-green;
  }
}

.message-show {
  transform: translateX(0px);
}

.icon {
  height: auto;
  width: 35px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -58px;
  color: #fff;
}

.message + .message {
  margin-top: 5px;
}
</style>
