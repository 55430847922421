import { createRouter, createWebHistory } from 'vue-router';
import { authGuard, showLoginPage } from '@/services/authentication/authenticationGuard';

// TODO see if its possible to make nested routes
//  https://router.vuejs.org/guide/essentials/nested-routes.html

const routes = [
  {
    path: '/',
    name: 'FrontPage',
    component: () => import('@/pages/Frontpage/Frontpage.vue'),
    beforeEnter: showLoginPage,
  },
  {
    path: '/logg-inn',
    name: 'Logg inn',
    component: () => import('@/pages/Login/Login.vue'),
    beforeEnter: showLoginPage,
  },
  {
    path: '/ordre',
    component: () => import('@/pages/Orders/OrderListPage.vue'),
    beforeEnter: authGuard,
    meta: {
      breadcrumb: [{ text: 'Ordre' }],
    },
  },
  {
    path: '/ordre/:id',
    component: () => import('@/pages/Orders/OrderPage.vue'),
    beforeEnter: authGuard,
    meta: {
      breadcrumb: [{ text: 'Ordre', to: '/ordre' }, { text: 'id' }],
    },
  },
  {
    // LAZYLOAD
    path: '/produkter',
    component: () => import('@/pages/Products/ProductList.vue'),
    beforeEnter: authGuard,
    meta: {
      breadcrumb: [{ text: 'Produkter' }],
    },
  },
  {
    // LAZYLOAD
    path: '/produkter/opprett-produkt',
    component: () => import('@/pages/Products/CreateProduct.vue'),
    beforeEnter: authGuard,
    meta: {
      breadcrumb: [{ text: 'Produkter', to: '/produkter' }, { text: 'Opprett produkt' }],
    },
  },
  {
    // LAZYLOAD
    path: '/produkter/:id',
    component: () => import('@/pages/Products/EditProduct.vue'),
    beforeEnter: authGuard,
    meta: {
      breadcrumb: [
        { text: 'Produkter', to: '/produkter' },
        { text: 'Rediger produkt' },
        { text: 'id' },
      ],
    },
  },
  {
    // LAZYLOAD
    path: '/meldinger/infoland',
    component: () => import('@/pages/Messages/InfolandMessage.vue'),
    beforeEnter: authGuard,
    meta: {
      breadcrumb: [{ text: 'Meldinger' }, { text: 'Melding i Infoland' }],
    },
  },
  {
    // LAZYLOAD
    path: '/meldinger',
    component: () => import('@/pages/Messages/MessageList.vue'),
    beforeEnter: authGuard,
    meta: {
      breadcrumb: [{ text: 'Meldinger' }, { text: 'Melding til formidlere' }],
    },
  },
  {
    // LAZYLOAD
    path: '/meldinger/opprett-melding',
    component: () => import('@/pages/Messages/CreateMessage.vue'),
    beforeEnter: authGuard,
    meta: {
      breadcrumb: [
        { text: 'Meldinger' },
        { text: 'Melding til formidlere', to: '/meldinger' },
        { text: 'Send melding' },
      ],
    },
  },
  {
    // LAZYLOAD
    path: '/meldinger/:id',
    component: () => import('@/pages/Messages/Message.vue'),
    beforeEnter: authGuard,
    meta: {
      breadcrumb: [
        { text: 'Meldinger' },
        { text: 'Melding til formidlere', to: '/meldinger' },
        { text: 'Melding' },
      ],
    },
  },
  {
    // LAZYLOAD
    path: '/rapporter',
    component: () => import('@/pages/Reports/Report.vue'),
    beforeEnter: authGuard,
    meta: {
      breadcrumb: [{ text: 'Rapporter' }],
    },
  },
  {
    // LAZYLOAD
    path: '/brukere',
    component: () => import('@/pages/Users/UserList.vue'),
    beforeEnter: authGuard,
    meta: {
      breadcrumb: [{ text: 'Brukere' }],
    },
  },
  {
    // LAZYLOAD
    path: '/brukere/opprett-bruker',
    component: () => import('@/pages/Users/CreateUser.vue'),
    beforeEnter: authGuard,
    meta: {
      breadcrumb: [{ text: 'Brukere', to: '/brukere' }, { text: 'Opprett bruker' }],
    },
  },
  {
    // LAZYLOAD
    //TODO: Don’t use username in URL as it reveals identity; GDPR issue.
    // From GDPR perspective we should probaly
    // not use the username in URL as it reveals identity
    path: '/brukere/:id',
    component: () => import('@/pages/Users/EditUser.vue'),
    beforeEnter: authGuard,
    meta: {
      breadcrumb: [{ text: 'Brukere', to: '/brukere' }, { text: 'id' }],
    },
  },
  {
    // LAZYLOAD
    path: '/avdelinger',
    component: () => import('@/pages/Departments/DepartmentList.vue'),
    beforeEnter: authGuard,
    meta: {
      breadcrumb: [{ text: 'Avdelinger' }],
    },
  },
  {
    // LAZYLOAD
    path: '/avdelinger/opprett-avdeling',
    component: () => import('@/pages/Departments/CreateDepartment.vue'),
    beforeEnter: authGuard,
    meta: {
      breadcrumb: [{ text: 'Avdelinger', to: '/avdelinger' }, { text: 'Opprett avdeling' }],
    },
  },
  {
    // LAZYLOAD
    path: '/avdelinger/:id',
    component: () => import('@/pages/Departments/EditDepartment.vue'),
    beforeEnter: authGuard,
    meta: {
      breadcrumb: [{ text: 'Avdelinger', to: '/avdelinger' }, { text: 'id' }],
    },
  },
  {
    // LAZYLOAD
    path: '/innstillinger/mottaksadresse-ordremelding',
    component: () => import('@/pages/Settings/OrderMessageReceiver.vue'),
    beforeEnter: authGuard,
    meta: {
      breadcrumb: [{ text: 'Innstillinger' }, { text: 'Mottaksadresse for ordremelding' }],
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
