<template>
  <div class="app-container">
    <kode-header v-if="isUserLoggedIn" />

    <main :class="isUserLoggedIn ? 'main-content' : 'main-content-login'">
      <router-view></router-view>
    </main>

    <kode-footer />
    <kode-notifications />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onUnmounted } from 'vue';
import KodeHeader from '@/components/Header/Header.vue';
import KodeFooter from '@/components/Footer/Footer.vue';
import { providedUserStore } from '@/providers/provider';
import KodeNotifications from './components/Notifications/Notifications.vue';
import preventNavigationOnDrop from '@/utils/preventNavigationOnDrop';

export default defineComponent({
  name: 'App',
  components: {
    KodeHeader,
    KodeFooter,
    KodeNotifications,
  },
  setup() {
    const userStore = providedUserStore();

    const isUserLoggedIn = computed(() => {
      return userStore.isLoggedIn.value;
    });

    preventNavigationOnDrop.mount();

    onUnmounted(() => {
      preventNavigationOnDrop.unmount();
    });

    return {
      userStore,
      isUserLoggedIn,
    };
  },
});
</script>

<style lang="scss">
.main-content-login {
  background: $aux-dark-purple;
  flex: 1;
}
</style>
