<template>
  <nav
    :class="{ 'main-menu': true, 'submenu-open': state.activeMenuItem !== '' }"
    aria-label="Hovedmeny"
  >
    <button class="back-button" aria-label="Tilbake til hovedmeny">
      <ArrowDown @click="closeSubMenu" />
    </button>

    <ul class="menu-list">
      <li
        :class="{ 'menu-list-item': true, active: state.activeMenuItem === menuItem.path }"
        v-for="menuItem in menuItems"
        :key="menuItem.path"
      >
        <router-link
          class="menu-label"
          v-if="!menuItem.children"
          :to="menuItem.path"
          @click.native="cleanUpBeforeNavigate"
        >
          {{ menuItem.label }}
        </router-link>

        <button
          class="menu-label"
          v-if="menuItem.children"
          @click="toggleSubMenu(menuItem.path)"
          @keydown.down.prevent="
            state.subMenuIsVisible ? nextMenuItem() : toggleSubMenu(menuItem.path)
          "
          @keydown.shift.tab="closeSubMenu"
          @keydown.exact.tab="closeSubMenu"
          @keydown.esc="closeSubMenu"
        >
          <span>{{ menuItem.label }}</span>
          <CaretDownIcon class="caret" />
        </button>

        <ul
          class="sub-menu-list"
          v-if="menuItem.children && state.activeMenuItem === menuItem.path"
        >
          <li
            class="sub-menu-list-item"
            v-for="childMenuItem in menuItem.children"
            :key="`${menuItem.path}-${childMenuItem.path}`"
            @keydown.down.prevent="nextMenuItem"
            @keydown.up.prevent="previousMenuItem"
            @keydown.tab.exact.stop="closeSubMenu"
            @keydown.shift.tab="closeSubMenu"
            @keydown.esc="focusCurrentMainMenuItem"
            v-click-away="closeSubMenu"
          >
            <router-link
              :to="childMenuItem.path"
              @click.native="cleanUpBeforeNavigate"
              ref="subMenuItems"
            >
              {{ childMenuItem.label }}
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { createPopper } from '@popperjs/core';
import menuItems from '@/components/Menu/Menu';
import CaretDownIcon from '@icons/regular/caret-down.svg';
import ArrowDown from '@icons/regular/arrow-down.svg';
import { defineComponent, reactive, watch, ref, onUnmounted } from 'vue';

export default defineComponent({
  props: {
    activeMenu: String,
  },
  name: 'KodeMenu',
  setup(props, { emit }) {
    const mediaQueryList = window.matchMedia('(max-width: 800px)');
    const subMenuItems = ref(null);
    const activeMainMenu = ref(null);
    const state = reactive({
      activeMenuItem: '',
      subMenuIsVisible: false,
      currentSubMenuIndex: 0,
      isSmallScreen: mediaQueryList.matches,
    });

    const focusCurrentMainMenuItem = () => {
      getCurrentMainMenuButton()?.focus();
      closeSubMenu();
    };

    const getCurrentMainMenuButton = () => {
      return document.querySelector('.menu-list-item.active button');
    };

    const nextMenuItem = () => {
      if (document.activeElement?.tagName === 'BUTTON') {
        state.currentSubMenuIndex = -1;
      }
      const nextMenuItem = subMenuItems.value[state.currentSubMenuIndex + 1];
      if (nextMenuItem !== undefined) {
        nextMenuItem.$el.focus();
        state.currentSubMenuIndex++;
      }
    };

    const previousMenuItem = () => {
      const previousMenuItem = subMenuItems.value[state.currentSubMenuIndex - 1];
      if (previousMenuItem !== undefined) {
        previousMenuItem.$el.focus();
        state.currentSubMenuIndex--;
      } else {
        focusCurrentMainMenuItem();
      }
    };

    const closeSubMenu = () => {
      state.activeMenuItem = '';
      state.subMenuIsVisible = false;
    };

    const cleanUpBeforeNavigate = () => {
      state.activeMenuItem = '';
      state.subMenuIsVisible = false;
      emit('close-main-menu');
    };

    const isMainMenuItem = (menuItem: { path: string; children?: string }) => {
      return menuItem.children ? true : false;
    };

    const toggleSubMenu = (path: string) => {
      state.subMenuIsVisible = !state.subMenuIsVisible;
      if (state.activeMenuItem === path) {
        state.activeMenuItem = '';
        emit('change-active-menu', '');
      } else {
        state.activeMenuItem = path;
        emit('change-active-menu', 'mainMenu');
      }
    };

    const resizeUpdate = (mediaQuery: MediaQueryListEvent) => {
      state.isSmallScreen = mediaQuery.matches;
    };

    mediaQueryList.addEventListener('change', resizeUpdate);

    onUnmounted(() => {
      mediaQueryList.removeEventListener('change', resizeUpdate);
    });

    watch(
      () => props.activeMenu,
      (activeMenu) => {
        const activeMenuIsUserMenu = activeMenu === 'userMenu';
        const noActiveMenuSet = activeMenu === '';

        if (activeMenuIsUserMenu || noActiveMenuSet) {
          state.activeMenuItem = '';
        }
      }
    );

    watch(
      () => subMenuItems.value,
      () => {
        if (subMenuItems.value[0] !== undefined && !state.isSmallScreen) {
          const el = subMenuItems.value[0].$el;
          const dropDown = el.parentElement.parentElement;
          const parentEle = dropDown.parentElement;
          createPopper(parentEle, dropDown, {
            placement: 'bottom-start',
          });
        }
      }
    );

    return {
      subMenuItems,
      activeMainMenu,
      menuItems,
      state,
      toggleSubMenu,
      closeSubMenu,
      cleanUpBeforeNavigate,
      isMainMenuItem,
      nextMenuItem,
      previousMenuItem,
      getCurrentMainMenuButton,
      focusCurrentMainMenuItem,
    };
  },
  components: {
    CaretDownIcon,
    ArrowDown,
  },
});
</script>

<style lang="scss" scoped>
$display-responsive-large: 1140px;

a {
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: 0 10px;
}

.main-menu {
  background: $aux-dark-purple;
  line-height: $application-header-height;

  @media screen and (max-width: $display-hamburger-menu-breakpoint) {
    width: 100%;
  }
}

.menu-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  white-space: nowrap;
  background-color: $aux-purple;
  width: 100%;
  font-weight: 500;

  @media screen and (min-width: $display-hamburger-menu-breakpoint) {
    flex-direction: row;
    background-color: $aux-dark-purple;
  }

  a:link,
  a:visited {
    color: $aux-white;
    text-decoration: none;

    @media screen and (max-width: $display-hamburger-menu-breakpoint) {
      width: 100%;
    }
  }

  a:hover,
  a:active {
    background: $aux-purple;

    @media screen and (max-width: $display-hamburger-menu-breakpoint) {
      background-color: $aux-dark-purple;
      width: 100%;
    }
  }
}

.menu-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  line-height: $application-header-height;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  padding: 0 20px;
  margin: 0;
  position: relative;
  @include aux-focus-indicator;

  span {
    color: $aux-white;
  }

  @media screen and (max-width: $display-responsive-large) {
    padding: 0 6px;
  }

  &:hover,
  &:active {
    background: $aux-purple;

    @media screen and (max-width: $display-hamburger-menu-breakpoint) {
      background-color: $aux-dark-purple;
      width: 100%;
    }
  }
}

.menu-list-item {
  display: flex;
  flex-direction: row;
  position: relative;

  @media screen and (max-width: $display-hamburger-menu-breakpoint) {
    &:not(:last-child) {
      border-bottom: 1px solid $aux-dark-purple;
    }
  }

  &::before {
    content: '';
  }
}

.menu-list-item.router-link-active {
  background: $aux-purple;
}

.sub-menu-list {
  background: $aux-dark-purple;
  display: none;
  border: 1px solid $aux-dark-purple;

  @media screen and (min-width: $display-hamburger-menu-breakpoint) {
    position: absolute;
    top: $application-header-height;
    width: auto;
    min-width: 130px;
    left: 0;
    z-index: 1;
  }

  @media screen and (max-width: $display-hamburger-menu-breakpoint) {
    border: none;
  }
}

.sub-menu-list-item {
  background: $aux-purple;

  &:hover {
    background: $aux-purple;
  }

  @media screen and (min-width: $display-hamburger-menu-breakpoint) {
    background-color: $aux-dark-purple;
  }
}

.caret {
  position: relative;
  top: 15px;
  width: 20px;
  color: $aux-white;
  transform: rotate(-90deg);
  margin-left: auto;

  @media screen and (min-width: $display-hamburger-menu-breakpoint) {
    transform: rotate(0deg);
    margin-left: 5px;
  }
}

.back-button {
  width: 50px;
  border: none;
  display: none;
  transform: rotate(90deg);
  margin-left: 15px;
  color: $aux-white;
  @include aux-focus-indicator;
}

.submenu-open {
  .caret {
    top: 0;
  }

  .back-button {
    height: $application-header-height;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (min-width: $display-hamburger-menu-breakpoint) {
      display: none;
    }
  }

  .menu-list-item.active {
    display: block;
  }

  .menu-label,
  .main-list-item > .menu-label,
  .menu-list-item {
    display: none;

    @media screen and (min-width: $display-hamburger-menu-breakpoint) {
      display: block;
    }
  }
}

.active .sub-menu-list {
  display: block;
}
</style>
