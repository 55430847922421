<template>
  <div>
    <div class="user-menu-wrapper">
      <button
        class="user-menu-toggle-button"
        aria-label="Åpne brukermeny"
        @click="toggleMenu"
        @keydown.down.prevent="state.open ? nextMenuItem() : toggleMenu()"
        @keydown.shift.tab="closeSubMenu"
        @keydown.exact.tab="closeSubMenu"
        @keydown.esc="closeSubMenu"
        v-click-away="closeSubMenu"
        ref="mainMenu"
        v-if="!isMobile"
      >
        <UserIcon class="user-icon" />
        <span class="user-name">{{ userFullName }}</span>
        <CaretDownIcon class="caret" />
      </button>

      <div class="user-mobile" v-if="isMobile">
        <UserIcon class="user-icon" />
        <span class="user-name">{{ userFullName }}</span>
        <CaretDownIcon class="caret" />
      </div>

      <nav aria-label="Brukermeny" class="navbar">
        <ul class="user-menu-items" :class="{ 'user-menu-items--open': state.open }">
          <li
            class="user-menu-item"
            @keydown.down.prevent="nextMenuItem"
            @keydown.up.prevent="previousMenuItem"
            @keydown.shift.tab.prevent="focusMainMenuItem"
            @keydown.esc="focusMainMenuItem"
            @keydown.exact.tab="closeSubMenu"
          >
            <router-link @click.native="cleanUpBeforeNavigate" ref="userBtn" :to="myUserUrl">
              Min bruker
            </router-link>
          </li>

          <li
            class="user-menu-item"
            @keydown.down.prevent="nextMenuItem"
            @keydown.up.prevent="previousMenuItem"
            @keydown.shift.tab.prevent="focusMainMenuItem"
            @keydown.esc="focusMainMenuItem"
            @keydown.exact.tab="closeSubMenu"
          >
            <button class="logout-button" @click="logout" ref="logoutButton">Logg ut</button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import UserIcon from '@icons/regular/user-circle.svg';
import CaretDownIcon from '@icons/regular/caret-down.svg';
import { defineComponent, reactive, computed, watch, ref } from 'vue';
import AuthenticationService from '@/services/authentication/authenticationService';
import { requireInjection } from '@/providers/injectorHelper';
import { PROVIDED_USERSTORE } from '@/providers/providerKeys';
import { useRouter } from 'vue-router';

export default defineComponent({
  props: {
    activeMenu: String,
  },
  setup(props, { emit }) {
    const userBtn = ref(null);
    const logoutButton = ref(null);
    const mainMenu = ref(null);
    const mediaQueryList = window.matchMedia('(max-width: 970px)');
    const isMobile = ref(mediaQueryList.matches);

    const router = useRouter();

    mediaQueryList.addEventListener('change', (event) => {
      isMobile.value = event.matches;
    });

    const state = reactive({
      open: props.activeMenu === 'userMenu',
      activeMenuItem: '',
      currentItemCount: 0,
    });

    const subMenuItems = 2;
    const userStore = requireInjection(PROVIDED_USERSTORE);

    const cleanUpBeforeNavigate = () => {
      state.open = false;
      emit('close-main-menu');
    };

    const closeSubMenu = () => {
      state.open = false;
    };

    const logout = () => {
      AuthenticationService.logout();
      userStore.deleteUser();
      router.push('/');
    };

    const focusMainMenuItem = () => {
      mainMenu.value.focus();
      closeSubMenu();
    };

    const nextMenuItem = () => {
      if (state.currentItemCount < subMenuItems) {
        state.currentItemCount++;
        switch (state.currentItemCount) {
          case 0:
            mainMenu.value.focus();
            break;
          case 1:
            userBtn.value.$el.focus();
            break;
          case 2:
            logoutButton.value.focus();
            break;
        }
      }
    };

    const previousMenuItem = () => {
      if (state.currentItemCount > 0) {
        state.currentItemCount--;
        switch (state.currentItemCount) {
          case 0:
            mainMenu.value.focus();
            closeSubMenu();
            break;
          case 1:
            userBtn.value.$el.focus();
            break;
        }
      }
    };

    const toggleMenu = () => {
      state.open = !state.open;
      state.currentItemCount = 0;
      if (state.open) {
        emit('change-active-menu', 'userMenu');
      } else {
        emit('change-active-menu', '');
      }
    };

    watch(
      () => props.activeMenu,
      (first) => {
        state.open = first === 'userMenu';
      }
    );

    watch(
      // () => root.$route.path,
      () => router.path,
      (newPath, prevPath) => {
        if (newPath !== prevPath) {
          state.activeMenuItem = '';
          emit('close-main-menu');
        }
      }
    );

    // TODO From GDPR perspective we should probaly not use the username in URL
    const myUserUrl = computed(() => {
      return `/brukere/${userStore?.state.user.logonIdent}`;
    });

    const isUserMenuOpen = computed(() => {
      return state.open;
    });

    const userFullName = computed(() => {
      return userStore?.state?.user?.name;
    });

    return {
      logoutButton,
      userBtn,
      mainMenu,
      logout,
      state,
      myUserUrl,
      userFullName,
      toggleMenu,
      isUserMenuOpen,
      cleanUpBeforeNavigate,
      closeSubMenu,
      nextMenuItem,
      previousMenuItem,
      focusMainMenuItem,
      isMobile,
    };
  },
  components: {
    UserIcon,
    CaretDownIcon,
  },
});
</script>

<style lang="scss" scoped>
$display-responsive-small: 970px;
$display-responsive-extra-small: 410px;
.user-menu-wrapper {
  display: flex;
  background-color: inherit;
  color: $aux-white;

  @media (min-width: $display-hamburger-menu-breakpoint) {
    flex-direction: column;
    position: relative;
  }

  @media screen and (max-width: $display-responsive-extra-small) {
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-grow: 1;
  }
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  line-height: 50px;
  @include aux-focus-indicator;

  &:hover,
  &:active {
    background: $aux-purple;
  }
}

.user-menu-toggle-button {
  display: flex;
  padding: 0 10px;

  @media screen and (max-width: $display-responsive-small) {
    padding: 2px;
  }
}

.user-mobile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  flex-flow: row;
  padding: 0 5px;
}

.user-icon {
  position: relative;
  width: 30px;
  margin-right: 5px;
  @media screen and (min-width: $display-hamburger-menu-breakpoint) {
    top: 9px;
  }
  @media screen and (max-width: $display-responsive-small) {
    margin-right: 0px;
  }
}

.user-name {
  color: $aux-white;
  white-space: nowrap;

  @media screen and (max-width: $display-hamburger-menu-breakpoint) {
    display: flex;
  }

  @media screen and (min-width: $display-hamburger-menu-breakpoint) and (max-width: $display-responsive-small) {
    display: none;
  }
}

.caret {
  position: relative;
  top: 13px;
  margin-left: 5px;
  display: none;
  width: 20px;

  @media (min-width: $display-hamburger-menu-breakpoint) {
    display: inline;
  }
}

.user-menu-items {
  display: flex;
  flex-direction: row;
  margin: 0;
  background-color: $aux-dark-purple;
  font-weight: 500;

  @media screen and (min-width: $display-hamburger-menu-breakpoint) {
    display: none;
    flex-direction: column;
    border: 1px solid $aux-dark-purple;
  }

  &--open {
    display: flex;
    position: absolute;
    top: $application-header-height;
    width: 100%;
    left: 0;
    right: 0;
  }
}

.user-menu-item {
  line-height: 50px;
  padding-left: 10px;
  padding-right: 10px;

  &:hover {
    background: $aux-purple;
  }
}

a:link,
a:visited {
  color: $aux-white;
  text-decoration: underline;

  @media screen and (min-width: $display-hamburger-menu-breakpoint) {
    text-decoration: none;
  }

  @media screen and (min-width: 500px) and (max-width: $display-hamburger-menu-breakpoint) {
    // this overrides sanitize.css safari voiceover fix
    &::before {
      content: '';
      border-left: 1px solid $aux-white;
      padding-right: 10px;
    }
  }

  display: inline-block;
  height: 100%;
  width: 100%;
}

.logout-button {
  padding-top: 0;
  text-decoration: underline;
  padding-left: 0;

  display: inline-block;
  height: 100%;
  width: 100%;
  text-align: left;

  @media screen and (min-width: $display-hamburger-menu-breakpoint) {
    text-decoration: none;
  }
}

.navbar {
  @media screen and (min-width: $display-responsive-small) {
    position: absolute;
    right: 0;
    width: 200px;
  }

  @media screen and (max-width: $display-hamburger-menu-breakpoint) {
    left: 215px;
  }
}
</style>
