<template>
  <ul class="messages">
    <KodeNotification
      v-for="notification in state.notifications"
      :key="notification.id"
      :id="notification.id"
      :type="notification.type"
      @remove-notification="removeNotification"
    >
      {{ notification.message }}
    </KodeNotification>
  </ul>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import UUID from '@/services/util/uuid';
import KodeNotification from './Notification.vue';
import EventBus from '@/services/eventBus/eventBus';

export default defineComponent({
  name: 'KodeNotifications',
  setup() {
    const state = reactive({
      notifications: [],
    });

    const displayNotification = (messageObject) => {
      const notification = {
        type: messageObject.type,
        message: messageObject.message,
        id: UUID(),
      };
      state.notifications.push(notification);
    };

    const removeNotification = (event: string) => {
      state.notifications = state.notifications.filter((notification) => notification.id !== event);
    };

    EventBus.on('notification', displayNotification);

    return { state, removeNotification };
  },
  components: {
    KodeNotification,
  },
});
</script>

<style lang="scss" scoped>
.messages {
  position: fixed;
  overflow: hidden;
  bottom: 20px;
  right: 20px;
  max-width: 450px;
  width: 100%;
  z-index: 99999;
}
</style>
