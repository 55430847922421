import mitt from 'mitt';

interface NotificationObject {
  message?: string;
  type: string;
  id?: string;
}

type Events = {
  notification: NotificationObject;
};

const EventBus = mitt<Events>();

export default EventBus;

// import Vue from 'vue';
// export const EventBus = new Vue();
