import { reactive, computed } from 'vue';
import { User } from '@/types/user';
import AuthenticationService from '@/services/authentication/authenticationService';
import { UserState } from '@/providers/providerTypes';

export const createStore = () => {
  const state: UserState = reactive({
    user: null,
  });

  const setUser = (user: User): void => {
    state.user = user;
  };

  const deleteUser = (): void => {
    state.user = null;
  };

  // If page refresh, this gets the user from localstorage to keep user object
  const userFromStorage = AuthenticationService.getUserFromStorage();
  if (userFromStorage) {
    setUser(userFromStorage);
  }

  const getUser = () => {
    return state.user;
  };

  const isLoggedIn = computed(() => {
    return !!state.user?.logonIdent;
  });

  return {
    setUser,
    getUser,
    deleteUser,
    isLoggedIn: isLoggedIn,
    state,
  };
};
