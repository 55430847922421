import { provide } from 'vue';
import { PROVIDED_USERSTORE } from './providerKeys';
import { UserStore } from './providerTypes';
import { createStore } from './userStore';

export const providedUserStore = (): UserStore => {
  const userStore = createStore();

  provide(PROVIDED_USERSTORE, userStore);

  return userStore;
};
