import { Credentials } from '@/types/authentication';
import { User } from '@/types/user';
import http from '@/services/http';
import router from '@/router';
import { AxiosResponse } from 'axios';
import { SUPPLIERS_BASE_URL } from '@/constants/urls';

const TOKEN_TIMESTAMP_KEY = 'kode-token-expiry';
const USER_OBJECT_KEY = 'user';

const setTokenTimeout = () => {
  const now = new Date();
  const expiryTimestamp = new Date(
    now.getTime() + parseInt(import.meta.env.VITE_TOKEN_EXPIRY_IN_MINUTES, 10) * 60000
  ).getTime();

  localStorage.setItem(TOKEN_TIMESTAMP_KEY, expiryTimestamp.toString());
};

const AuthenticationApi = http(`${SUPPLIERS_BASE_URL}/authentication`);
const UserApi = http(`${SUPPLIERS_BASE_URL}/users`);

export default {
  async login(credentials: Credentials): Promise<User | string> {
    const tokenResponse = await this._loginAndSetCookie(credentials);

    if (typeof tokenResponse === 'string') {
      return tokenResponse;
    }

    const userResponse = await this.getUser(credentials.username);

    if (typeof userResponse !== 'string') {
      this._storeUserInStorage(userResponse);
    }

    return userResponse;
  },

  _loginAndSetCookie(credentials: Credentials): Promise<string | unknown> {
    return AuthenticationApi.post('/login', credentials).then((response) => {
      setTokenTimeout();

      return response;
    });
  },

  getUser(userIdent: string | null): Promise<User | string> {
    return UserApi.get(`/${userIdent}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  },

  _storeUserInStorage(user: User): void {
    window.localStorage.setItem(USER_OBJECT_KEY, JSON.stringify(user));
  },

  getUserFromStorage(): User {
    const userFromSession = window.localStorage.getItem(USER_OBJECT_KEY);

    if (userFromSession) {
      JSON.parse(userFromSession);
    }

    return userFromSession ? JSON.parse(userFromSession) : ({} as User);
  },

  _deleteUserFromStorage(): void {
    window.localStorage.removeItem(USER_OBJECT_KEY);
    window.localStorage.removeItem(TOKEN_TIMESTAMP_KEY);
    router.go(0);
  },

  refreshAuthentication(): Promise<AxiosResponse> {
    return AuthenticationApi.post('/refresh').then((response) => {
      setTokenTimeout();
      return response;
    });
  },

  isAuthenticated(): boolean {
    const user = this.getUserFromStorage();
    return !!user.logonIdent;
  },

  async logout(): Promise<void> {
    await AuthenticationApi.post('/logout');
    this._deleteUserFromStorage();
  },

  logoutUser(): void {
    this._deleteUserFromStorage();
  },
};
