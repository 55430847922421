import router from '@/router';
import AuthenticationService from '@/services/authentication/authenticationService';
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
} from 'vue-router';

export const authGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalizedLoaded,
  next: NavigationGuardNext
): void => {
  if (AuthenticationService.isAuthenticated()) {
    return next();
  }

  router.push({ path: '/', query: { redirect: to.fullPath } }).catch(() => {
    // just empty to prevent an error due to multiple loads
  });
};

export const showLoginPage = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalizedLoaded,
  next: NavigationGuardNext
): void => {
  if (AuthenticationService.isAuthenticated()) {
    router.push('/ordre');
  }

  next();
};
